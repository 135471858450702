import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Context } from 'store/provider';
import Link from 'gatsby-link';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Filters from './FilterActions';
import DownArrow from './downarrow.svg';
import SearchIcon from '@material-ui/icons/Search';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CloseIcon from '@material-ui/icons/Close';

require('typeface-roboto');

const styles = theme => ({
  searchBar: {
    position: 'relative',
    bottom: 0,
    left: 0,
    width: '100%',
    //background: 'rgba(255, 255, 255, 0.8)',
    paddingTop: '0px',
    //paddingBottom: '16px',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
    },
  },
  input: {
    fontFamily: 'TTHovesRegular, Arial, sans-serif',
    width: '100%',
    height: '40px',
    padding: '10px',
    borderRadius: '0px',
    border: '1px solid',
    borderColor: theme.palette.background.border,
    outline: 0,
    color: theme.palette.primary.main,
    '&.last': {
      borderRight: `1px solid ${theme.palette.background.border}`,
    },
    [theme.breakpoints.up('md')]: {
      borderRight: '0px',
    },
  },
  selectWrapper: {
    position: 'relative',
    '& .select': {
      appearance: 'none',
      cursor: 'pointer',
    },
    '&:after': {
      content: "''",
      backgroundImage: `url(${DownArrow})`,
      width: '15px',
      height: '15px',
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
  },
  label: {
    display: 'inline-block',
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      marginBottom: '0.35rem',
    },
    '&.hidden': {
      [theme.breakpoints.up('md')]: {
        visibility: 'hidden',
      },
    },
    '&.alwaysHidden': {
      visibility: 'hidden',
    },
  },
  submitBtn: {
    fontFamily: 'Roboto-Medium, sans-serif',
    fontSize: '13px',
    textTransform: 'uppercase',
    color: theme.palette.text.white,
    backgroundColor: theme.palette.primary.main,
    height: '40px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    transition: 'background-color 0.3s ease-in-out 100ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.border,
    },
    '&:disabled': {
      cursor: 'not-allowed',
      backgroundColor: theme.palette.background.disabled,
    },
  },
  icon: {
    marginLeft: '0.5rem',
  },
  suggested: {
    position: 'absolute',
    maxHeight: '15.625rem',
    top: 'auto',
    right: '0',
    left: '0',
    textAlign: 'left',
    backgroundColor: '#fff',
    overflow: 'hidden',
    padding: '20px',
    bottom: '100%',
  },
  scroll: {
    '-ms-touch-action': 'auto',
    touchAction: 'auto',
    overflow: 'scroll !important',
    '-ms-overflow-style': 'none',
    maxHeight: '10.8125rem',
  },
  listItem: {
    listStyle: 'none',
    padding: 0,
    margin: '10px 0px',
  },
  listItemIcon: {
    marginRight: '10px',
  },
  geoBtnPos: {
    position: 'absolute',
    right: '5px',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
  },
  geoBtn: {
    background: 'transparent',
    border: '0px',
    padding: 0,
    outline: 0,
    color: theme.palette.primary.main,
    width: '20px',
    cursor: 'pointer',
    transition: 'color 0.3s ease-in-out 100ms',
    '&:hover': {
      color: theme.palette.background.border,
    },
  },
  geoIcon: {
    width: '20px',
  },
  filtersOverlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    zIndex: '110',
  },
  closeWrapper: {
    position: 'absolute',
    top: '50px',
    right: '50px',
  },
  closeBtn: {
    background: 'transparent',
    border: 0,
    paddding: 0,
    cursor: 'pointer',
    '& svg': {
      color: theme.palette.primary.main,
      transition: 'color 0.3s ease-in-out',
    },
    '&:hover': {
      '& svg': {
        color: theme.palette.text.lblue,
      },
    },
  },
  updateFiltersBtn: {
    fontSize: '0.7rem',
    display: 'inline-block',
    cursor: 'pointer',
    border: `3px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    color: theme.palette.text.white,
    padding: '5px 20px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.text.white}`,
    transition: 'box-shadow 0.2s ease-in-out',
    [theme.breakpoints.up('md')]: {
      padding: '8px 20px',
    },
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.text.white}`,
    },
  },
  filtersOpenTxt: {
    background: 'transparent',
    border: 0,
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      '& .btnTxt': {
        textDecoration: 'underline',
      },
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});

function SearchBar(props) {
  const { classes, updateSearch, locationUpdateAvailable, frontPage } = props;
  const [state, dispatch] = useContext(Context);
  const [progress, setProgress] = useState(0);
  const [gettingLocation, setGettingLocation] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const data = useStaticQuery(graphql`
    query Query {
      prismic {
        allDevelopments {
          edges {
            node {
              title
            }
          }
        }
      }
    }
  `);

  const developments = [];

  data.prismic.allDevelopments.edges.map(item => {
    developments.push(item.node.title[0].text);
  });

  const SearchUpdate = event => {
    dispatch({
      ...state,
      type: 'SET_SEARCH',
      [event.target.name]: event.target.value,
    });
    if (updateSearch && event.target.name != 'location') {
      props.updateResults();
    }
    if (updateSearch && event.target.name == 'location') {
      props.checkIfLocationIsChanging(event.target.value);
    }
  };

  const geoLocate = () => {
    setGettingLocation(true);
    const timer = setInterval(() => {
      setProgress(prevProgress =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    navigator.geolocation.getCurrentPosition(position => {
      dispatch({
        ...state,
        type: 'SET_SEARCH',
        location: 'YOUR LOCATION',
        geometry: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
        your_location: [
          { lat: position.coords.latitude },
          { lng: position.coords.longitude },
        ],
      });
      clearInterval(timer);
      setGettingLocation(false);
      if (updateSearch) {
        props.updateResults(position);
      }
    });
  };

  return (
    <React.Fragment>
      <Box className={classes.searchBar}>
        {frontPage && (
          <Box
            style={{
              backgroundColor: 'rgba(0, 172, 88, 0.9)',
              padding: '20px 0px',
            }}
          >
            <Container maxWidth="lg">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={{ xs: 'column', md: 'row' }}
              >
                <Box style={{ textAlign: 'center' }}>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    style={{
                      color: '#fff',
                      fontSize: '1.5rem',
                      display: isMediumScreen ? 'block' : 'flex',
                    }}
                  >
                    REDEFINING
                    <Typography
                      component="span"
                      variant="body1"
                      style={{
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                        fontSize: '2rem',
                        margin: '0px 15px 0px 10px',
                        textTransform: 'capitalize',
                      }}
                    >
                      EPC A-Rated
                    </Typography>
                    HOMES
                  </Typography>
                </Box>
                <Box style={{ marginTop: isMediumScreen ? '15px' : '0px' }}>
                  <a
                    href="https://www.hayfieldhomes.co.uk/zero-carbon"
                    style={{
                      border: '1px solid #fff',
                      color: '#fff',
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      padding: '10px 20px',
                      display: 'flex',
                    }}
                  >
                    <Typography component="span" variant="subtitle1">
                      FIND OUT MORE
                    </Typography>
                  </a>
                </Box>
              </Box>
            </Container>
          </Box>
        )}
        <Box
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '16px 0px',
          }}
        >
          <Container maxWidth="lg">
            <Box
              display={{ xs: 'block', md: 'flex' }}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <Box
                display={{ xs: 'none', md: 'flex' }}
                alignItems="center"
                flexBasis={{ md: 0 }}
                flexGrow={{ md: 1 }}
                mr={3}
              >
                <Typography component="p" variant="subtitle1" align="center">
                  Find your <br />
                  perfect home
                </Typography>
              </Box>
              <Box display="flex" flex="0 0 30%" flexDirection="column">
                <label htmlFor="location" className={classes.label}>
                  <Typography component="span" variant="caption">
                    Where are you looking to buy?
                  </Typography>
                </label>
                <Box position="relative">
                  <Box position="relative">
                    <Box className={classes.geoBtnPos}>
                      {!gettingLocation ? (
                        <button
                          aria-label="Select your location"
                          onClick={() => geoLocate()}
                          className={classes.geoBtn}
                        >
                          <MyLocationIcon className={classes.geoIcon} />
                        </button>
                      ) : (
                        <CircularProgress
                          variant="static"
                          value={progress}
                          style={{ height: '20px', width: '20px' }}
                        />
                      )}
                    </Box>
                    <input
                      className={classes.input}
                      id="location"
                      type="text"
                      name="location"
                      value={state.location || ''}
                      onChange={SearchUpdate}
                      placeholder="Search by Postcode, area or development"
                      autoComplete="off"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                flexBasis={{ md: 0 }}
                flexGrow={{ md: 1 }}
                flexDirection="column"
              >
                <label htmlFor="radius" className={`${classes.label} hidden`}>
                  <Typography component="span" variant="caption">
                    Radius
                  </Typography>
                </label>
                <Box className={classes.selectWrapper}>
                  <select
                    id="radius"
                    name="radius"
                    value={state.radius || ''}
                    onBlur={SearchUpdate}
                    onChange={SearchUpdate}
                    className={`${classes.input} select`}
                    disabled={locationUpdateAvailable}
                  >
                    <option value="10">+ 10 miles</option>
                    <option value="20">+ 20 miles</option>
                    <option value="40">+ 40 miles</option>
                    <option value="60">+ 60 miles</option>
                    <option value="80">+ 80 miles</option>
                    <option value="100">+ 100 miles</option>
                  </select>
                </Box>
              </Box>
              <Box
                display="flex"
                flexBasis={{ md: 0 }}
                flexGrow={{ md: 1 }}
                flexDirection="column"
              >
                <label htmlFor="bedrooms" className={classes.label}>
                  <Typography component="span" variant="caption">
                    Bedrooms
                  </Typography>
                </label>
                <Box className={classes.selectWrapper}>
                  <select
                    id="bedrooms"
                    name="bedrooms"
                    value={state.bedrooms || ''}
                    onBlur={SearchUpdate}
                    onChange={SearchUpdate}
                    className={`${classes.input} select last`}
                    disabled={locationUpdateAvailable}
                  >
                    <option value="1">All</option>
                    <option value="1">1 bed +</option>
                    <option value="2">2 beds +</option>
                    <option value="3">3 beds +</option>
                    <option value="4">4 beds +</option>
                    <option value="5">5 beds +</option>
                  </select>
                </Box>
              </Box>
              <Box display="flex" flex="0 0 20%" flexDirection="column">
                <label
                  htmlFor="submit"
                  className={`${classes.label} alwaysHidden`}
                >
                  <Typography component="span" variant="caption">
                    Submit
                  </Typography>
                </label>
                {!updateSearch ? (
                  <Link
                    id="submit"
                    className={classes.submitBtn}
                    to={`/${process.env.SEARCH_RESULTS_URL}`}
                  >
                    <Box component="span">Search Properties</Box>
                    <SearchIcon className={classes.icon} />
                  </Link>
                ) : (
                  <button
                    aria-label="Update results"
                    onClick={() => props.updateResults()}
                    className={classes.submitBtn}
                    disabled={!locationUpdateAvailable}
                  >
                    <Box component="span">Update Location</Box>
                    <SearchIcon className={classes.icon} />
                  </button>
                )}
              </Box>
            </Box>
            {frontPage && (
              <Box
                width="100"
                display="flex"
                alignItems="center"
                justifyContent="center"
                pt={1}
              >
                <button
                  aria-label="Open filters"
                  onClick={() => setOpenFilters(true)}
                  className={classes.filtersOpenTxt}
                >
                  <Typography
                    component="span"
                    variant="button"
                    color="textPrimary"
                    className={`${classes.uppercase} btnTxt`}
                  >
                    Advanced Search filters
                  </Typography>
                </button>
              </Box>
            )}
            {frontPage && openFilters && (
              <Box
                className={classes.filtersOverlay}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
              >
                <Box className={classes.closeWrapper}>
                  <button
                    aria-label="Close filters"
                    onClick={() => setOpenFilters(false)}
                    className={classes.closeBtn}
                  >
                    <CloseIcon fontSize="large" />
                  </button>
                </Box>
                <Box>
                  <Filters />
                  <Box display="flex" justifyContent="center" pt={4}>
                    <button
                      aria-label="Update filters"
                      onClick={() => setOpenFilters(false)}
                      className={classes.updateFiltersBtn}
                    >
                      Update Filters
                    </button>
                  </Box>
                </Box>
              </Box>
            )}
          </Container>
        </Box>
      </Box>
    </React.Fragment>
  );
}

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
  updateSearch: PropTypes.bool,
  updateResults: PropTypes.func,
  checkIfLocationIsChanging: PropTypes.func,
  locationUpdateAvailable: PropTypes.bool,
  frontPage: PropTypes.bool,
};

export default withStyles(styles)(SearchBar);
