import React, { useState, useContext, useEffect } from 'react';
import { Context } from 'store/provider';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Detached from 'images/Detached.svg';
import SemiDetached from 'images/semi-detached.svg';
import Bungalow from 'images/bungalow.svg';
import Flat from 'images/flats.svg';

import Slider from '@material-ui/core/Slider';

const styles = theme => ({
  filterBtn: {
    backgroundColor: theme.palette.text.white,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '20px',
    cursor: 'pointer',
    padding: '8px 22px',
    transition: 'all 0.3s ease-in-out',
    outline: 0,
    '&:hover': {
      backgroundColor: theme.palette.text.lblue,
      border: `1px solid ${theme.palette.text.lblue}`,
    },
    '&.active': {
      backgroundColor: theme.palette.text.lblue,
      border: `1px solid ${theme.palette.text.lblue}`,
    },
    '&.thickBorder': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  filterBox: {
    position: 'fixed',
    background: theme.palette.text.white,
    borderRadius: '4px',
    boxShadow: '0px 0px 8px 0px rgba(31, 50, 66, 0.24)',
    zIndex: 5,
    marginTop: '0px',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      minWidth: '600px',
      width: 'auto',
      height: 'auto',
      marginTop: '10px',
    },
  },
  btn: {
    cursor: 'pointer',
    border: '1px solid transparent',
    borderRadius: '4px',
    background: 'transparent',
    padding: '10px 20px',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      border: `1px solid ${theme.palette.text.lblue}`,
    },
    '&.active': {
      border: `1px solid ${theme.palette.text.lblue}`,
    },
  },
  icon: {
    marginBottom: '10px',
    height: '50px',
  },
  action: {
    borderTop: `1px solid ${theme.palette.text.lblue}`,
  },
  saveBtn: {
    display: 'inline-block',
    cursor: 'pointer',
    border: `3px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    color: theme.palette.text.white,
    padding: '5px 10px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.text.white}`,
    transition: 'box-shadow 0.2s ease-in-out',
    [theme.breakpoints.up('md')]: {
      padding: '8px 20px',
    },
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.text.white}`,
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  smlIcon: {
    '& svg': {
      height: '20px',
      width: '40px',
    },
  },
});

const HouseTypesFilters = ['Detached', 'Semi-detached', 'Bungalow', 'Flat'];

function Filters(props) {
  const { classes, updateResults } = props;
  const [state, dispatch] = useContext(Context);
  const [housetype, setHousetypes] = useState(false);
  const [numOfBeds, setNumOfBeds] = useState(false);
  const [priceRange, setPriceRange] = useState(false);
  const [selectedHouseTypes, setSelectedHouseTypes] = useState([]);
  const [numBedsValue, setNumBedsValue] = useState([1, 5]);
  const [priceRangeValue, setPriceRangeValue] = useState([100000, 1000000]);

  const marksBeds = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5 +',
    },
  ];

  const marksPrice = [
    {
      value: 100000,
      label: '£100k',
    },
    {
      value: 200000,
      label: '£200k',
    },
    {
      value: 300000,
      label: '£300k',
    },
    {
      value: 400000,
      label: '£400k',
    },
    {
      value: 500000,
      label: '£500k',
    },
    {
      value: 600000,
      label: '£600k',
    },
    {
      value: 700000,
      label: '£700k',
    },
    {
      value: 800000,
      label: '£800k',
    },
    {
      value: 900000,
      label: '£900k',
    },
    {
      value: 1000000,
      label: '£1m +',
    },
  ];

  const NumBedsValueText = value => {
    return `${value}`;
  };

  const PriceRangeValueText = value => {
    return `${value == '1000000' ? '1m' : `${value / 1000}k`}`;
  };

  const handleBedsRangeChange = (event, value) => {
    setNumBedsValue(value);
  };

  const handlePriceRangeChange = (event, value) => {
    setPriceRangeValue(value);
  };

  const openHomeTypes = () => {
    setHousetypes(!housetype);
    setNumOfBeds(false);
    setPriceRange(false);
  };

  const openNumofBeds = () => {
    setNumOfBeds(!numOfBeds);
    setHousetypes(false);
    setPriceRange(false);
  };

  const openPriceRange = () => {
    setPriceRange(!priceRange);
    setHousetypes(false);
    setNumOfBeds(false);
  };

  const updateHouseTypesFilters = item => {
    if (selectedHouseTypes.includes(item)) {
      const newList = selectedHouseTypes.filter(i => i !== item);
      setSelectedHouseTypes(newList);
    } else {
      setSelectedHouseTypes([...selectedHouseTypes, item]);
    }
  };

  const HouseTypeButton = props => {
    const { item, classes } = props;
    switch (item) {
      case 'Detached':
        return (
          <button
            aria-label="Update house type filters"
            onClick={() => updateHouseTypesFilters(item)}
            className={`${classes.btn} ${
              state.houseTypes.includes(item) ? 'active' : ''
            }`}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Detached className={classes.icon} />
              <Typography
                component="span"
                variant="button"
                className={classes.uppercase}
              >
                {item}
              </Typography>
            </Box>
          </button>
        );
      case 'Semi-detached':
        return (
          <button
            aria-label="Update house type filters"
            onClick={() => updateHouseTypesFilters(item)}
            className={`${classes.btn} ${
              state.houseTypes.includes(item) ? 'active' : ''
            }`}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <SemiDetached className={classes.icon} />
              <Typography
                component="span"
                variant="button"
                className={classes.uppercase}
              >
                {item}
              </Typography>
            </Box>
          </button>
        );
      case 'Bungalow':
        return (
          <button
            aria-label="Update house type filters"
            onClick={() => updateHouseTypesFilters(item)}
            className={`${classes.btn} ${
              state.houseTypes.includes(item) ? 'active' : ''
            }`}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Bungalow className={classes.icon} />
              <Typography
                component="span"
                variant="button"
                className={classes.uppercase}
              >
                {item}
              </Typography>
            </Box>
          </button>
        );
      case 'Flat':
        return (
          <button
            aria-label="Update house type filters"
            onClick={() => updateHouseTypesFilters(item)}
            className={`${classes.btn} ${
              state.houseTypes.includes(item) ? 'active' : ''
            }`}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Flat className={classes.icon} />
              <Typography
                component="span"
                variant="button"
                className={classes.uppercase}
              >
                Apartment
              </Typography>
            </Box>
          </button>
        );
      default:
        return null;
    }
  };

  HouseTypeButton.propTypes = {
    item: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
  };

  const SelectedHouseTypesIconsSml = data => {
    const items = data.data;
    return (
      <Box display="flex" alignItems="center">
        {items.map(item => {
          if (item == 'Detached') {
            return (
              <Box
                display="flex"
                alignItems="center"
                className={classes.smlIcon}
              >
                <Detached />
              </Box>
            );
          }
          if (item == 'Semi-detached') {
            return (
              <Box
                display="flex"
                alignItems="center"
                className={classes.smlIcon}
              >
                <SemiDetached />
              </Box>
            );
          }
          if (item == 'Bungalow') {
            return (
              <Box
                display="flex"
                alignItems="center"
                className={classes.smlIcon}
              >
                <Bungalow />
              </Box>
            );
          }
          if (item == 'Flat') {
            return (
              <Box
                display="flex"
                alignItems="center"
                className={classes.smlIcon}
              >
                <Flat />
              </Box>
            );
          }
        })}
      </Box>
    );
  };

  useEffect(() => {
    dispatch({ ...state, type: 'SET_SEARCH', houseTypes: selectedHouseTypes });
  }, [selectedHouseTypes]);

  useEffect(() => {
    dispatch({ ...state, type: 'SET_SEARCH', numOfBedsRange: numBedsValue });
  }, [numBedsValue]);

  useEffect(() => {
    dispatch({ ...state, type: 'SET_SEARCH', priceRange: priceRangeValue });
  }, [priceRangeValue]);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        flexWrap="wrap"
        justifyContent="center"
      >
        <Box position="relative" mb={[2, 2, 0]}>
          <Box>
            <button
              aria-label="Open house type filters"
              onClick={openHomeTypes}
              className={`${classes.filterBtn} ${housetype ? 'active' : ''} ${
                state.houseTypes && state.houseTypes.length > 0
                  ? 'thickBorder'
                  : ''
              }`}
            >
              <Typography
                component="span"
                variant="button"
                className={classes.uppercase}
              >
                <Box display="flex" alignItems="center">
                  <Box component="span">Property Type</Box>{' '}
                  {state.houseTypes && state.houseTypes.length > 0 && (
                    <Box component="span" ml={2}>
                      <SelectedHouseTypesIconsSml data={state.houseTypes} />
                    </Box>
                  )}
                </Box>
              </Typography>
            </button>
          </Box>
        </Box>

        <Box px={[2, 2, 2]} position="relative" mb={[2, 2, 0]}>
          <Box>
            <button
              aria-label="Open price filters"
              onClick={openPriceRange}
              className={`${classes.filterBtn} ${priceRange ? 'active' : ''} ${
                state.priceRange && state.priceRange.length > 0
                  ? 'thickBorder'
                  : ''
              }`}
            >
              <Typography
                component="span"
                variant="button"
                className={classes.uppercase}
              >
                <Box display="flex">
                  <Box component="span">Price</Box>{' '}
                  {state.priceRange && state.priceRange.length > 0 && (
                    <Box component="span" ml={2} className={classes.bold}>
                      {state.priceRange[0] == '1000000'
                        ? '1m'
                        : `£${state.priceRange[0] / 1000}k`}
                      {' - '}
                      {state.priceRange[1] == '1000000'
                        ? '1m'
                        : `£${state.priceRange[1] / 1000}k`}
                    </Box>
                  )}
                </Box>
              </Typography>
            </button>
          </Box>
        </Box>

        <Box position="relative" mb={[2, 2, 0]}>
          <Box>
            <button
              aria-label="Open bedroom filters"
              onClick={openNumofBeds}
              className={`${classes.filterBtn} ${numOfBeds ? 'active' : ''} ${
                state.numOfBedsRange && state.numOfBedsRange.length > 0
                  ? 'thickBorder'
                  : ''
              }`}
            >
              <Typography
                component="span"
                variant="button"
                className={classes.uppercase}
              >
                <Box display="flex">
                  <Box component="span">Bedrooms</Box>{' '}
                  {state.numOfBedsRange && state.numOfBedsRange.length > 0 && (
                    <Box component="span" ml={2} className={classes.bold}>
                      {state.numOfBedsRange[0]} -{' '}
                      {state.numOfBedsRange[1] == '5'
                        ? state.numOfBedsRange[1] + ' + '
                        : state.numOfBedsRange[1]}
                    </Box>
                  )}
                </Box>
              </Typography>
            </button>
          </Box>
        </Box>
      </Box>
      <Box position="relative">
        {housetype && (
          <Box className={classes.filterBox}>
            <Box pt={4} px={4}>
              <Typography
                component="p"
                variant="button"
                className={classes.uppercase}
              >
                Choose the type of property you’re interested in:
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              mt={2}
              px={2}
              flexWrap="wrap"
            >
              {HouseTypesFilters.map((item, key) => (
                <Box key={key} mr={1} mb={[2, 2, 0, 0]}>
                  <HouseTypeButton item={item} classes={classes} />
                </Box>
              ))}
            </Box>
            <Box
              className={classes.action}
              px={4}
              py={2}
              mt={2}
              display="flex"
              justifyContent="flex-end"
            >
              <button
                aria-label="Save filters"
                className={classes.saveBtn}
                onClick={() => {
                  updateResults ? props.updateResults() : null;
                  setHousetypes(false);
                }}
              >
                Save
              </button>
            </Box>
          </Box>
        )}
        {numOfBeds && (
          <Box className={classes.filterBox}>
            <Box pt={4} px={4}>
              <Typography
                component="p"
                variant="button"
                className={classes.uppercase}
              >
                Choose the number of bedrooms
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" mt={6} px={6}>
              <Slider
                defaultValue={1}
                value={numBedsValue}
                getAriaValueText={NumBedsValueText}
                onChange={handleBedsRangeChange}
                aria-labelledby="range-slider"
                valueLabelDisplay="on"
                step={1}
                marks={marksBeds}
                min={1}
                max={5}
              />
            </Box>
            <Box
              className={classes.action}
              px={4}
              py={2}
              mt={2}
              display="flex"
              justifyContent="flex-end"
            >
              <button
                aria-label="Update results"
                className={classes.saveBtn}
                onClick={() => {
                  updateResults ? props.updateResults() : null;
                  setNumOfBeds(false);
                }}
              >
                Save
              </button>
            </Box>
          </Box>
        )}
        {priceRange && (
          <Box className={classes.filterBox}>
            <Box pt={4} px={4}>
              <Typography
                component="p"
                variant="button"
                className={classes.uppercase}
              >
                Choose the price range
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" mt={6} px={6}>
              <Slider
                defaultValue={1}
                value={priceRangeValue}
                getAriaValueText={PriceRangeValueText}
                getAriaLabel={PriceRangeValueText}
                onChange={handlePriceRangeChange}
                valueLabelFormat={PriceRangeValueText}
                aria-labelledby="range-slider"
                valueLabelDisplay="on"
                step={100000}
                marks={marksPrice}
                min={100000}
                max={1000000}
              />
            </Box>
            <Box
              className={classes.action}
              px={4}
              py={2}
              mt={2}
              display="flex"
              justifyContent="flex-end"
            >
              <button
                aria-label="Update results"
                className={classes.saveBtn}
                onClick={() => {
                  updateResults ? props.updateResults() : null;
                  setPriceRange(false);
                }}
              >
                Save
              </button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  updateResults: PropTypes.func,
};

export default withStyles(styles)(Filters);
